/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Images
import Chevron from 'img/chevron.svg'

// Interface
import { FormProps } from 'components/flex/Form/FormShell'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  background: ${({ theme }) => theme.color.light};

  & .form-duuf-group {
    margin-bottom: 2rem;
  }

  /* & .form-duuf-group-checkbox {
    position: relative;
    width: 100% !important;
    background-color: rgb(242, 242, 242) !important;
    padding: 0.5rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    max-width: 614px;

    & > div > div {
      border-radius: 0;
      border: 0;
      padding: 6px 5px;
    }

    & .form-duuf-label {
      width: 100%;
      min-height: 39px;
      font-size: 16px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      &:hover {
        cursor: pointer;
      }

      &:after {
        margin-top: 14px;
        content: url(${Chevron});
        margin-left: 14.65rem;
        transform: rotate(90deg) !important;
        width: 24px;
      }
    }

    & > div {
      display: none;
      position: absolute;
      width: 100%;
      top: 52px;
      left: 0;
      border-style: solid;
      border-width: 1px;
      background-color: rgb(242, 242, 242);
      padding: 2rem;
      z-index: 1;
    }
  } */

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #f2f2f2;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 10px 20px;
    background-color: #f2f2f2;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-group-select {
    width: 100% !important;

    & > div > div {
      background-color: rgb(242, 242, 242) !important;
      border-radius: 0;
      border: 0;
      padding: 6px 5px;
    }
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  z-index: 2;
`

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    opacity: 0.1;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
    @media (min-width: 1400px) {
      font-size: 100px;
    }
    @media (max-width: 1399px) {
      font-size: 80px;
    }
    @media (max-width: 1199px) {
      font-size: 70px;
    }
    @media (max-width: 767px) {
      font-size: 40px;
    }
    @media (max-width: 575px) {
      font-size: 30px;
    }
  }
`
const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 36px;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
    @media (max-width: 575px) {
      line-height: 35px;
    }
  }
`

const FormConsultation: React.FC<FormProps> = ({ fields }) => {
  const [selectHovered, setSelectHovered] = useState(false)

  // if (typeof window !== `undefined`) {
  //   const selectBox = document.getElementsByClassName(
  //     'form-duuf-checkboxes-input_12'
  //   )

  //   React.useEffect(() => {
  //     if (selectBox[0]) {
  //       selectBox[0].addEventListener('mouseover', () => setSelectHovered(true))
  //       selectBox[0].addEventListener('mouseleave', () =>
  //         setSelectHovered(false)
  //       )
  //     }

  //     if (selectBox[0]) {
  //       if (selectHovered) {
  //         // @ts-ignore
  //         selectBox[0].lastElementChild.classList.add('d-block')
  //         const htmlCollection =
  //           document.getElementsByClassName('form-duuf-checkbox')

  //         const placeholder = Array.from(htmlCollection).filter((input) =>
  //           input.id.includes('placeholder')
  //         )
  //         // @ts-ignore
  //         placeholder[0].parentElement.classList.add('d-none')
  //       } else {
  //         // @ts-ignore
  //         selectBox[0].lastElementChild.classList.remove('d-block')
  //       }
  //     }
  //   }, [selectHovered])
  // }

  return (
    <section className="mb-5 pb-lg-5" id={fields.sectionid}>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row justify-content-center">
              <Title content={fields.description} className="mb-3" />
              <div className="col-lg-8">
                <FormWrapper className="position-relative p-sm-5  p-4">
                  <Content
                    content={fields.formdescription}
                    className="mb-sm-5 mb-4"
                  />
                  <StyledFormDuuf
                    id={2}
                    generate={false}
                    scrollToConfirm={false}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <FormDuufGroup hideIds={[7, 1, 10, 12, 14, 15]} />
                      </div>
                      <div className="col-md-6">
                        <FormDuufGroup
                          showIds={[7, 1, 14]}
                          hideIds={[10, 12, 13, 15]}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <FormDuufGroup showIds={[15]} />
                    </div>
                    <div className="row">
                      <FormDuufSubmit />
                    </div>
                  </StyledFormDuuf>
                </FormWrapper>
              </div>
              <Title content={fields.descriptionbelow} className="mt-3" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FormConsultation
