/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import Plaatjie from '@ubo/plaatjie'

// Interface
import { FormProps } from 'components/flex/Form/FormShell'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  background: ${({ theme }) => theme.color.light};

  & .form-duuf-group {
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #f2f2f2;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 10px 20px;
    background-color: #f2f2f2;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const FormWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
`

const Socials = styled.div`
  & a {
    font-size: 0;
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
`

const Info = styled.div`
  & a:hover {
    text-decoration: underline;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 36px;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const Table = styled.table`
  width: 70%;
`

const FormContact: React.FC<FormProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row">
            <div className="col-lg-4 mt-5 mb-5 mb-lg-0">
              <ParseContent
                content={fields.description || ''}
                className="mb-4"
              />
              <Info className="mb-4">
                {fields.contactinfo?.map((i, index) => (
                  <div className="d-flex" key={index}>
                    <a href={i?.link?.url}>
                      <Plaatjie image={i?.icon} alt="" className="me-2" />
                      {i?.link?.title}
                    </a>
                  </div>
                ))}
              </Info>

              <div className="mb-4">
                <p className="mb-0">
                  <strong>Openingstijden</strong>
                </p>
                <Table>
                  {fields?.opentimes?.map((time, index) => (
                    <tr key={index}>
                      <td>{time?.day}</td>
                      <td>{time?.time}</td>
                    </tr>
                  ))}
                </Table>
              </div>

              <Socials className="d-flex align-items-center mb-5">
                {fields.socials?.map((s, index) => (
                  <a href={s?.link?.url} key={index}>
                    <Plaatjie
                      image={s?.icon}
                      className="me-3"
                      alt=""
                      style={
                        s?.link?.url?.includes('instagram')
                          ? { marginTop: 3 }
                          : { marginTop: 0 }
                      }
                    />
                    {s?.link?.title}
                  </a>
                ))}
              </Socials>

              <div>
                <ParseContent content={fields.descriptionbelow} />
              </div>
            </div>
            <div className="col-lg-8">
              <FormWrapper className="p-lg-5 p-4">
                <Content content={fields.formdescription} className="mb-5" />
                <StyledFormDuuf id={1} generate={false}>
                  <div className="row">
                    <div className="col-lg-6">
                      <FormDuufGroup hideIds={[7, 9, 1, 2]} />
                    </div>
                    <div className="col-lg-6">
                      <FormDuufGroup showIds={[7, 9, 1]} />
                    </div>
                  </div>
                  <div className="row">
                    <FormDuufGroup showIds={[2]} />
                  </div>
                  <div className="row">
                    <FormDuufSubmit />
                  </div>
                </StyledFormDuuf>
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default FormContact
