

import React from 'react'
import styled from 'styled-components'

import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'
import { FormProps } from 'components/flex/Form/FormShell'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit
} from 'components/related/Form'

const Section = styled.section`
  @media (min-width: 992px) {
    margin-bottom: 4rem;
  }
`
const StyledFormDuuf = styled(FormDuuf)`
position: relative;
background: ${({ theme }) => theme.color.light};

& .form-duuf-group {
  margin-bottom: 2rem;
}

& .form-duuf-textarea,
& .form-duuf-input {
  width: 100%;
  border-color: #f2f2f2;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  padding: 10px 20px;
  background-color: #f2f2f2;
}

& .form-duuf-textarea {
  height: 100px;
}

& .form-duuf-file-button {
  ${ButtonSecondaryCSS};
}

& .form-duuf-file-name {
  margin-left: 0.5rem;
}

& .form-duuf-checkbox-label {
  margin-left: 0.5rem;
  margin-bottom: 0;
}

& .form-duuf-label-mandatory {
  color: ${({ theme }) => theme.color.secondary};
}

& .form-duuf-select__control {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  min-height: 38px;
  padding: 4px 10px;
  border-radius: 0;
}

& .form-duuf-error {
  color: tomato;
}

& .form-duuf-submit {
  ${ButtonPrimaryCSS};

  &-disabled {
    opacity: 0.5;
  }
}

& .form-duuf-confirm {
  color: green;
}

& .form-duuf-something-wrong {
  color: red;
}

& .form-duuf-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
}
`

const FormWrapper = styled.div`
position: relative;
background-color: ${({ theme }) => theme.color.light};
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 36px;
    }
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const Costs = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  padding: 2rem;
`

const FormMaintenance: React.FC<FormProps> = ({ fields }) => (
  <Section id={fields.sectionid}>
    <div className="container py-lg-5">
      <div className="row justify-content-between">
        <div className="col-lg-7 mb-4 mb-md-0">
          <div className="row">
            <div className="col-lg-12">
              <FormWrapper className="p-lg-5 p-4">
                <Content content={fields.formdescription} className="mb-5" />
                <StyledFormDuuf
                  id={5}
                  generate={false}
                >
                  <InnerForm />
                </StyledFormDuuf>
              </FormWrapper>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <Costs>
            <p className="font-size-big font-weight-bold mb-1">Kosten onderhoudscontract</p>
            <p><em>Voorrijkosten speciaal tarief</em></p>
            <p className="d-flex justify-content-between">
              <span>Voorrijkosten binnen gemeente Bunschoten</span>
              <span>€ 30,00</span>
            </p>
            <p className="d-flex justify-content-between">
              <span>Voorrijkosten buiten gemeente Bunschoten</span>
              <span>€ 45,00</span>
            </p>
            <p><em>Bovenstaande prijzen zijn inclusief BTW</em></p>

          </Costs>
        </div>
      </div>
    </div>
  </Section>
)

function InnerForm() {

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <FormDuufGroup showIds={[4]} />
        </div>
        <div className="col-lg-6">
          <FormDuufGroup showIds={[7]} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FormDuufGroup showIds={[8]} />
        </div>
        <div className="col-lg-6">
          <FormDuufGroup showIds={[9]} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FormDuufGroup showIds={[6]} />
        </div>
        <div className="col-lg-6">
          <FormDuufGroup showIds={[1]} />
        </div>
      </div>
      <div className="row">
        <FormDuufGroup showIds={[12]} />
      </div>
      <div className="row">
        <FormDuufGroup showIds={[13]} />
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FormDuufSubmit />
        </div>
      </div>
    </>
  )
}

export default FormMaintenance
